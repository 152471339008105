<script>
import qs from "qs";
import * as d3 from "d3";
import * as c3 from "c3";
import Rails from "rails-ujs";

export default {
  props: ["csv_download_endpoint", "ids", "list_id"],
  data() {
    return {
      date_begin: "",
      date_end: "",
      groups: [],
      metrics: "",
    };
  },
  methods: {
    chartXaxisTimeseriesSetting() {
      return {
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%Y-%m-%d",
            },
          },
        },
      };
    },
    chartXaxisIndexedSetting() {
      return {
        axis: {
          x: {
            type: "indexed",
          },
        },
      };
    },
    chartDefaultSettings(params) {
      return Object.assign({
        legend: {
          show: false,
          hide: true,
        },
        types: {
          x: "line",
        },
        data: {
          columns: [],
        },
        tooltip: {
          format: {
            value: d3.format(","),
          },
        },
      }, params);
    },
    async createChart(bindTo, params) {
      if (this.$chart !== undefined) {
        this.$chart = this.$chart.destroy();
      }
      await this.$nextTick();
      this.$chart = c3.generate(Object.assign(this.chartDefaultSettings(params), { bindto: bindTo }));
    },
    downloadRawCsv(data) {
      const link = document.createElement("a");
      link.setAttribute("data-method", "post");
      link.setAttribute("href", `${this.csv_download_endpoint}?${qs.stringify(data, { arrayFormat: "brackets" })}`);
      link.addEventListener("click", Rails.handleMethod);
      link.click();
    },
    changeDataTypeToLine() {
      this.onChangeDataType("line");
    },
    changeDataTypeToArea() {
      this.onChangeDataType("area");
    },
    onChangeDataType(dataType) {
      this.$data.data_type = dataType;
      if (this.$data.data_type === "line") {
        this.$chart.transform("line");
        this.$chart.groups([]);
      } else if (this.$data.data_type === "area") {
        this.$chart.transform("area");
        this.$chart.groups([this.$data.groups]);
      }
    },
    renderChart(items) {
      if (items.length === 0) {
        this.$chart.load({});
        return;
      }
      const columns = [];
      const xs = []
      this.$data.groups = [];
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        xs[item.title] = `x${i}`
        columns.push([`x${i}`].concat(item.dates));
        columns.push([item.title].concat(item.metrics));
        this.$data.groups.push(item.title);
      }
      this.$chart.load({
        xs: xs,
        columns: columns
      })
      this.onChangeDataType(this.$data.data_type);
    },
  },
};
</script>
