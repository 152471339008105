import Vue from "vue";
import store from "./store/index";
import PopUp from "vc-popup/lib/vc-popup";

// components
import YoutubeViewerReport from "./components/YoutubeChannelList/ViewerReport.vue";
import YoutubeChannelChart from "./components/YoutubeChart/YoutubeChannelChart.vue";
import YoutubeVideoChart from "./components/YoutubeChart/YoutubeVideoChart.vue";
import ScoutCheckbox from "./components/YoutubeChannel/ScoutCheckbox.vue";

// pages
import UuumChannelsIndex from "./pages/UuumChannelsIndex.vue";
import YoutubeChannelListShow from "./pages/YoutubeChannelListShow.vue";
import YoutubeChannelsIndex from "./pages/YoutubeChannel.vue";

Vue.component("youtube-viewer-report", YoutubeViewerReport);
Vue.component("youtube-channel-chart", YoutubeChannelChart);
Vue.component("youtube-video-chart", YoutubeVideoChart);
Vue.component("scout-checkbox", ScoutCheckbox);

Vue.component("uuum-channels-index", UuumChannelsIndex);
Vue.component("youtube-channel-list-show", YoutubeChannelListShow);
Vue.component("youtube-channels-index", YoutubeChannelsIndex);

document.addEventListener("DOMContentLoaded", () => {
  Vue.use(PopUp);
  new Vue({ el: "#main", store });
});
