// 生jQueryだよ！がんばって！
import axios from "axios"
import * as serialize from "form-serialize"

const $ = jQuery; // eslint-disable-line

// チャンネル
document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("js-channel-mylist-shortcut")
  if (el === null) {
    return
  }
  const listEndpoint = el.dataset.list_endpoint
  const updateEndpoint = el.dataset.update_endpoint

  const OpenChannelMylistDialog = async (target) => {
    const { youtubeChannelTitle, youtubeChannelId } = target.dataset

    $(".js-add-to-channel-mylist-dialog-youtube-channel-title").html(youtubeChannelTitle)
    $(".js-channel-mylist-list").html("")
    $("#js-add-to-channel-mylist-dialog form input[name=\"youtube_channel_id\"]").val(youtubeChannelId)
    $("#js-add-to-channel-mylist-dialog").modal("show")

    const response = await axios.get(listEndpoint, {
      params: { youtube_channel_id: youtubeChannelId },
      responseType: "json"
    })
    const json = response.data
    const list = json.youtube_channels_list
    for (let i = 0; i < list.length; i += 1) {
      let checked = ""
      if (list[i].checked) {
        checked = "checked=\"checked\""
      }
      const html = `<li><label><input type="checkbox" name="youtube_channel_list_id[]" ${checked} value="${list[i].id}" />&nbsp;${list[i].title}</label></li>`
      $(".js-channel-mylist-list").append(html)
    }
  }
  window.OpenChannelMylistDialog = OpenChannelMylistDialog // todo jQueryやめたい

  // 表示
  $(".js-add-to-channel-list-dialog").click((event) => {
    const { target } = event
    OpenChannelMylistDialog(target)
    return false
  })

  // 保存
  $(".js-add-to-channel-mylist-dialog-submit").click(async () => {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
    const data = serialize(document.querySelector("#js-add-to-channel-mylist-dialog form"))
    await axios.post(updateEndpoint, data, {
      responseType: "json"
    })
    $("#js-add-to-channel-mylist-dialog").modal("hide")
  })
})

// 動画
document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("js-video-mylist-shortcut")
  if (el === null) {
    return
  }
  const listEndpoint = el.dataset.list_endpoint
  const updateEndpoint = el.dataset.update_endpoint

  // 表示
  $(".js-add-to-video-list-dialog").click(async (event) => {
    const { target } = event
    const { youtubeVideoTitle, youtubeVideoId } = target.dataset

    $(".js-add-to-video-mylist-dialog-youtube-video-title").html(youtubeVideoTitle)
    $(".js-video-mylist-list").html("")
    $("#js-add-to-video-mylist-dialog form input[name=\"youtube_video_id\"]").val(youtubeVideoId)
    $("#js-add-to-video-mylist-dialog").modal("show")

    const response = await axios.get(listEndpoint, {
      params: { youtube_video_id: youtubeVideoId },
      responseType: "json"
    })
    const json = response.data
    const list = json.youtube_videos_list
    for (let i = 0; i < list.length; i += 1) {
      let checked = ""
      if (list[i].checked) {
        checked = "checked=\"checked\""
      }
      const html = `<li><label><input type="checkbox" name="youtube_video_list_id[]" ${checked} value="${list[i].id}" />&nbsp;${list[i].title}</label></li>`
      $(".js-video-mylist-list").append(html)
    }

    return false
  })

  // 保存
  $(".js-add-to-video-mylist-dialog-submit").click(async () => {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken
    const data = serialize(document.querySelector("#js-add-to-video-mylist-dialog form"))
    await axios.post(updateEndpoint, data, {
      responseType: "json"
    })
    $("#js-add-to-video-mylist-dialog").modal("hide")
  })
})
