import channel from "../../api/youtubeChannels/channel"
import scout from "../../api/youtubeChannels/scout"

const state = {
  channels: []
}

const getters = {
  channelCount: (state) => {
    return state.channels.length
  },
  channelIds: (state) => {
    return state.channels.map(channel => {
      return channel.id;
    });
  },
  hasChannel: (state) => (channelId) => {
    return state.channels.some(channel => {
      return channel.id === channelId
    })
  }
}

const mutations = {
  setChannels (state, channels) {
    state.channels = channels
  },
  addChannels (state, channelId) {
    state.channels.push({'id': channelId})
  },
  removeChannels (state, channelId) {
    state.channels = state.channels.filter(target => target.id !== channelId)
  }
}

const actions = {
  async fetchChannel({commit, state, getters}) {
    const params = {
      channelIds: getters.channelIds
    }
    const response = await channel(params)
    commit("setChannels", response.data.youtubeChannels)
  },
  async sendScoutTargets({commit, state, getters}) {
    try {
      await scout(getters.channelIds)
      commit("setChannels", [])
    } catch (err) {
      alert('スカウト情報の送信に失敗しました')
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
