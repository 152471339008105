<template>
  <div>
    <h3 class="mb-2">YouTubeチャンネル</h3>
    <p><img src="/images/ag-grid-expand.png">←このアイコンをクリックすると隠れている項目が展開されます。</p>
    <p>項目名をクリックすることで並び替えをすることができます。</p>
    <ag-grid-vue
      style="width: 100%; height: 600px;"
      class="ag-theme-balham"
      row-height="40"
      multi-sort-key="true"
      enable-col-resize="true"
      enable-sorting="true"
      group-headers="true"
      :column-defs="columnDefs"
      :row-data="youtubeChannels"/>
    <div class="card-footer">
      <a :href="csvDownloadUrl" target="_blank" class="btn btn-info">
        <i class="material-icons pr-2"> file_download </i>
        CSVエクスポート (50000件まで)
      </a>
      <p>※CSVの項目「7日間」と「30日間」の数値については、期間指定と連動していません</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { agFilterNumber, agFilterDate, agFilterPercentage, agFilterDollar } from "../../lib/Filters";
import ChannelListItemTitle from "./ChannelListItemTitle.vue";

export default {
  props: {
    list_id: {
      type: Number,
      default: null,
    },
    youtubeChannels: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
      default: null,
    },
    month: {
      type: Number,
      default: null,
    },
  },
  components: {
    AgGridVue,
    ChannelListItemTitle,
  },
  data() {
    return {
      columnDefs: null,
    };
  },
  computed: {
    csvDownloadUrl() {
      return `/youtube_channel_lists/${this.list_id}.csv?year=${this.year}&month=${this.month}`; // `
    },
  },
  methods: {
    createColumnDefs() {
      return [
        {
          headerName: "基本情報",
          children: [
            {
              headerName: "",
              field: "thumbnailImageUrl",
              pinned: true,
              width: 80,
              cellRendererFramework: ChannelListItemTitle,
            },
            {
              headerName: "チャンネル名",
              field: "title",
              pinned: true,
              width: 150,
            },
            {
              headerName: "ID",
              field: "id",
              width: 150,
              columnGroupShow: "open",
            },
            {
              headerName: "開設日",
              field: "publishedAt",
              width: 150,
              columnGroupShow: "open",
              cellRenderer: agFilterDate(),
            },
            {
              headerName: "所属",
              field: "mcnName",
              width: 150,
              columnGroupShow: "open",
            },
            {
              headerName: "最終動画アップ",
              field: "lastVideoUploadedAt",
              width: 150,
              columnGroupShow: "open",
              cellRenderer: agFilterDate(),
            },
            {
              headerName: "違反",
              field: "auditStatusSummary",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "統計情報",
          children: [
            {
              headerName: "総再生数",
              field: "viewCount",
              width: 120,
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "登録者数",
              field: "subscribersCount",
              width: 120,
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "動画本数",
              field: "videoCount",
              width: 120,
              cellRenderer: agFilterNumber(),
            },
          ],
        },
        {
          headerName: "再生回数",
          children: [
            {
              headerName: "今月",
              field: "statistics.viewCount.monthly",
              width: 120,
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "着地",
              field: "statistics.viewCount.monthlyLanding",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "先月",
              field: "statistics.viewCount.lastMonthly",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "前月比",
              field: "statistics.viewCount.m2mRate",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterPercentage({}),
            },
          ],
        },
        {
          headerName: "チャンネル登録者数",
          children: [
            {
              headerName: "今月",
              field: "statistics.subscribersCount.monthly",
              width: 120,
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "着地",
              field: "statistics.subscribersCount.monthlyLanding",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "先月",
              field: "statistics.subscribersCount.lastMonthly",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "前月比",
              field: "statistics.subscribersCount.m2mRate",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterPercentage({}),
            },
          ],
        },
        {
          headerName: "コメント数",
          children: [
            {
              headerName: "今月",
              field: "statistics.commentCount.monthly",
              width: 120,
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "着地",
              field: "statistics.commentCount.monthlyLanding",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "先月",
              field: "statistics.commentCount.lastMonthly",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "前月比",
              field: "statistics.commentCount.m2mRate",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterPercentage({}),
            },
          ],
        },
        {
          headerName: "動画本数",
          children: [
            {
              headerName: "今月",
              field: "statistics.videoCount.monthly",
              width: 120,
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "着地",
              field: "statistics.videoCount.monthlyLanding",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "先月",
              field: "statistics.videoCount.lastMonthly",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterNumber(),
            },
            {
              headerName: "前月比",
              field: "statistics.videoCount.m2mRate",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterPercentage({}),
            },
          ],
        },
        {
          headerName: "収益",
          children: [
            {
              headerName: "今月",
              field: "statistics.estimatedRevenue.monthly",
              width: 120,
              cellRenderer: agFilterDollar({}),
            },
            {
              headerName: "着地",
              field: "statistics.estimatedRevenue.monthlyLanding",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterDollar({}),
            },
            {
              headerName: "先月",
              field: "statistics.estimatedRevenue.lastMonthly",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterDollar({}),
            },
            {
              headerName: "前月比",
              field: "statistics.estimatedRevenue.m2mRate",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterPercentage({}),
            },
          ],
        },
        {
          headerName: "RPM",
          children: [
            {
              headerName: "今月",
              field: "statistics.rpm.monthly",
              width: 120,
              cellRenderer: agFilterDollar({ digit: 6 }),
            },
            {
              headerName: "着地",
              field: "statistics.rpm.monthlyLanding",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterDollar({ digit: 6 }),
            },
            {
              headerName: "先月",
              field: "statistics.rpm.lastMonthly",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterDollar({ digit: 6 }),
            },
            {
              headerName: "前月比",
              field: "statistics.rpm.m2mRate",
              width: 120,
              columnGroupShow: "open",
              cellRenderer: agFilterPercentage({}),
            },
          ],
        },
      ];
    },
  },
  created() {
    this.columnDefs = this.createColumnDefs();
  },
};
</script>
