<template>
  <div class="mt-3 mb-4 pl-4">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="form-group row">
          <label>期間</label>
          <input type="date" class="form-control datepicker col-md-5 mr-2 mt-3" v-model="date_begin"> <span class="mt-4">〜</span>
          <input type="date" class="form-control datepicker col-md-5 ml-1 mt-3" v-model="date_end">
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <label class="mb-0">項目</label>
        <select class="selectpicker col-md-12 pl-0" data-style="select-with-transition pr-0" v-model="metrics">
          <optgroup label="累計（推定値）">
            <option value="view_count">累計 再生数</option>
            <option value="subscriber_count">累計 チャンネル登録者数</option>
          </optgroup>
          <optgroup label="日次（確定値）">
            <option value="views">再生数</option>
            <option value="subscribers_gained">チャンネル登録数（増加）</option>
            <option value="subscribers_lost">チャンネル登録解除数（減少）</option>
            <option value="likes">高評価数</option>
            <option value="dislikes">低評価数</option>
            <option value="comments">コメント数</option>
            <option value="average_view_duration">平均視聴時間</option>
            <option value="video_count">動画本数</option>
          </optgroup>
        </select>
      </div>
     <div class="col-md-4 col-sm-6 mt-4 ml-2">
        <div class="row">
          <div class="form-check col-md-5 col-sm-6">
            <label class="form-check-label">
              <input class="form-check-input" checked="checked" name="video_analytics_graph_type" type="radio" v-on:click="changeDataTypeToLine" value="1">折れ線
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check col-md-5 col-sm-6">
            <label class="form-check-label">
              <input class="form-check-input" name="video_analytics_graph_type" type="radio" v-on:click="changeDataTypeToArea" value="2">積み重ね
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-4">
      <div class="col-md-12">
        <button class="btn btn-info" v-on:click="downloadChannelCsv">
          <i class="material-icons pr-2">file_download</i>
          CSVダウンロード
        </button>
      </div>
    </div>
    <div ref="chart"></div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import YoutubeChartBase from "./YoutubeChartBase.vue";

export default {
  mixins: [YoutubeChartBase],
  props: ["api_endpoint", "csv_download_endpoint", "ids", "list_id"],
  data() {
    return {
      date_begin: moment().subtract(3 + 7, "days").format("YYYY-MM-DD"),
      date_end: moment().subtract(3, "days").format("YYYY-MM-DD"),
      groups: [],
      data_type: "line",
      metrics: "views",
    };
  },
  methods: {
    downloadChannelCsv() {
      this.downloadRawCsv(this.getRequestParams());
    },
    async reloadChart() {
      const response = await axios.get(this.api_endpoint, {
        params: this.getRequestParams(),
        responseType: "json",
      });
      const { channels } = response.data;
      this.renderChart(channels);
    },
    getRequestParams() {
      const data = {
        date_begin: this.$data.date_begin,
        date_end: this.$data.date_end,
        metrics: this.$data.metrics,
      };
      if (this.ids != null) {
        data.ids = this.ids.split(",");
      }
      if (this.list_id != null) {
        data.list_id = this.list_id;
      }
      return data;
    },
  },
  watch: {
    metrics() {
      if (this.$data.metrics === "") {
        return;
      }
      this.reloadChart();
    },
    date_begin() {
      if (this.$data.date_begin === "") {
        return;
      }
      this.reloadChart();
    },
    date_end() {
      if (this.$data.date_end === "") {
        return;
      }
      this.reloadChart();
    },
  },
  async mounted() {
    await this.createChart(this.$refs.chart, this.chartXaxisTimeseriesSetting());
    await this.$nextTick();
    this.reloadChart();
  },
};
</script>
