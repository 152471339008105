<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="card">
          <div class="card-header-primary card-header-text">
            <div class="card-text">
              <h4 class="card-title">スカウト情報の送信</h4>
            </div>
          </div>
          <div class="card-body">
            <h6>
              送信されたデータは、
              <a :href="codaScoutUrl" target="_blank">codaスカウトページ</a>ですぐに確認・管理することができます。
              <br>raptorに反映されるまでには、5分〜20分ほどかかるためご注意ください
            </h6>
            <button
              @click="modalShow"
              class="btn btn-success"
              v-bind:disabled="isSendButtonDisable"
            >
            送信
            <i class="material-icons pl-2">send</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal>
      <div>
        <p>{{ scoutTargetCount }}件のデータが送信対象になっています。</p>
        <ul>
          <li v-for="(channel) in channels" v-bind:key="channel.id">
            <input type="checkbox" :value="channel.id" @change="modifyScoutTargets" checked>
            <label>{{ channel.title }}</label>
          </li>
        </ul>
      </div>
      <template slot="footer">
        <button class="btn btn-success" @click="send" v-bind:disabled="isSendButtonDisable">
          codaへ送信
          <i class="material-icons pl-2">send</i>
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import Modal from "../components/Global/Modal.vue";
export default {
  name: "app",
  props: ['codaAppUrl'],
  components: {
    Modal
  },
  data() {
    return {
      codaScoutUrl: this.codaAppUrl + '/scouts',
    }
  },
  computed: {
    isSendButtonDisable() {
      return this.scoutTargetCount === 0;
    },
    ...mapGetters("youtubeChannels", {
      scoutTargetCount: "channelCount"
    }),
    ...mapState("youtubeChannels", {
      channels: "channels"
    })
  },
  methods: {
    modalShow() {
      this.$store.dispatch("youtubeChannels/fetchChannel");
      this.$store.commit("modal/show");
    },
    modifyScoutTargets(event) {
      if (event.target.checked) {
        this.$store.commit("youtubeChannels/addChannels", event.target.value);
      } else {
        this.$store.commit(
          "youtubeChannels/removeChannels",
          event.target.value
        );
      }
    },
    send() {
      this.$store.dispatch("youtubeChannels/sendScoutTargets");
      this.$store.commit("modal/hide");
    }
  }
};
</script>
