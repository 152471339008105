import youtubeChannelListChannelList from "../../api/youtubeChannelList/channelList"
import youtubeChannelListCreatorList from "../../api/youtubeChannelList/creatorList"
import youtubeChannelListSummary from "../../api/youtubeChannelList/summary"

const state = {
  reportStartDate: null,
  reportEndDate: null,
  summary: {
    viewCount: null,
    subscribersCount: null,
    videoCount: null,
    commentCount: null,
    estimatedRevenue: null,
    rpm: null
  },
  youtubeChannels: [],
  youtubeCreators: [],
  loading: false,
  loadingSummary: false
}

const getters = {
}

const mutations = {
  clearAllData (state) {
    state.youtubeChannels = []
    state.youtubeCreators = []
    state.reportStartDate = null
    state.reportEndDate = null
    state.summary.viewCount = null
    state.summary.subscribersCount = null
    state.summary.videoCount = null
    state.summary.commentCount = null
    state.summary.estimatedRevenue = null
    state.summary.rpm = null
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setLoadingSummary (state, loadingSummary) {
    state.loadingSummary = loadingSummary
  },
  setReportDate (state, { startDate, endDate }) {
    state.reportStartDate = startDate
    state.reportEndDate = endDate
  },
  setYoutubeChannelData (state, data) {
    state.youtubeChannels = data
  },
  setYoutubeCreatorData (state, data) {
    state.youtubeCreators = data
  },
  setSummaryData (state, data) {
    if (data.viewCount) {
      state.summary.viewCount = data.viewCount
    }
    if (data.subscribersCount) {
      state.summary.subscribersCount = data.subscribersCount
    }
    if (data.videoCount) {
      state.summary.videoCount = data.videoCount
    }
    if (data.commentCount) {
      state.summary.commentCount = data.commentCount
    }
    if (data.estimatedRevenue) {
      state.summary.estimatedRevenue = data.estimatedRevenue
    }
    if (data.rpm) {
      state.summary.rpm = data.rpm
    }
  }
}

const actions = {
  async reloadAll ({ commit }, { params }) {
    commit("setLoading", true)
    commit("setLoadingSummary", true)
    commit("clearAllData")
    try {
      const channelResponse = await youtubeChannelListChannelList(params)
      commit("setYoutubeChannelData", channelResponse.data.youtubeChannels)
      commit("setLoading", false)
      const creatorResponse = await youtubeChannelListCreatorList(params)
      commit("setYoutubeCreatorData", creatorResponse.data.youtubeCreators)
      const summaryResponse = await youtubeChannelListSummary(params)
      commit("setReportDate", { startDate: summaryResponse.data.reportStartDate, endDate: summaryResponse.data.reportEndDate })
      commit("setSummaryData", summaryResponse.data.summary)
      commit("setLoadingSummary", false)
    } catch (err) {
      commit("setLoading", false)
      commit("setLoadingSummary", false)
      alert(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
