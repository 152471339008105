<template>
  <div>
    <div class="row">
      <div v-bind:class="gridWidthClass">
        <button class="btn btn-info" v-on:click="downloadCsv">
          <i class="material-icons pr-2">file_download</i>
            CSVダウンロード
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-lg-12 col-md-12">
        <div v-bind:class="gridWidthClass">
          <div class="card card-default my-2">
                <a
                  href="#"
                  class="row px-3 py-2"
                  @click="expand('gender')"
                  :data-toggle="collapse"
                  data-target="#genderChart"
                >
                  <div class ="col-md-8">
                    <h4 class="card-title"> 性別 </h4>
                  </div>
                  <div class="col-md-4 text-right">
                    <i class="fa" :class="sign('gender')"></i>
                  </div>
                </a>
            <div class="card-body collapse show" id="genderChart">
              <div ref="gender_chart"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-12">
        <div v-bind:class="gridWidthClass">
          <div class="card card-default my-2">
            <a
              href="#"
              class="row px-3 py-2"
              @click="expand('age')"
              :data-toggle="collapse"
              data-target="#ageGroupChart"
            >
              <div class ="col-md-8">
                <h4 class="card-title"> 年齢層 </h4>
              </div>
              <div class="col-md-4 text-right">
                <i class="fa" :class="sign('age')"></i>
              </div>
            </a>
            <div class="card-body collapse show" id="ageGroupChart">
              <div ref="age_group_chart"></div>
              <h6>※ 小数点第3位を四捨五入しているため、必ずしも100%とはなりません</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="displayExtendMetrics">
      <div class="row">
        <div class="col-xl-6 col-lg-12 col-md-12">
          <div v-bind:class="gridWidthClass">
            <div class="card card-default collapsed-box my-2">
              <a
                href="#"
                class="row px-3 py-2"
                @click="expand('device')"
                :data-toggle="collapse"
                data-target="#deviceChart"
              >
                <div class ="col-md-8">
                  <h4 class="card-title"> デバイス </h4>
                </div>
                <div class="col-md-4 text-right">
                  <i class="fa" :class="sign('device')"></i>
                </div>
              </a>
              <div class="card-body collapse" id="deviceChart">
                <div ref="device_chart"></div>
              </div>
            </div>
          </div>
          </div>
        <div class="col-xl-6 col-lg-12 col-md-12">
          <div v-bind:class="gridWidthClass">
            <div class="card card-default collapsed-box my-2">
              <a
                href="#"
                class="row px-3 py-2"
                @click="expand('os')"
                :data-toggle="collapse"
                data-target="#osChart"
              >
                <div class ="col-md-8">
                  <h4 class="card-title"> OS </h4>
                </div>
                <div class="col-md-4 text-right">
                  <i class="fa" :class="sign('os')"></i>
                </div>
              </a>
            <div class="card-body collapse" id="osChart">
              <div ref="os_chart"></div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-12 col-md-12">
          <div v-bind:class="gridWidthClass">
            <div class="card card-default collapsed-box my-2">
              <a
                href="#"
                class="row px-3 py-2"
                @click="expand('location')"
                :data-toggle="collapse"
                data-target="#playbackLocationChart"
              >
                <div class ="col-md-8">
                  <h4 class="card-title"> 視聴場所 </h4>
                </div>
                <div class="col-md-4 text-right">
                  <i class="fa" :class="sign('location')"></i>
                </div>
              </a>
              <div class="box-body collapse" id="playbackLocationChart">
                <div ref="playback_location_chart"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12">
          <div v-bind:class="gridWidthClass">
            <div class="card card-default collapsed-box my-2">
                <a
                  href="#"
                  class="row px-3 py-2"
                  @click="expand('traffic')"
                  :data-toggle="collapse"
                  data-target="#trafficSourceChart"
                >
                  <div class ="col-md-8">
                    <h4 class="card-title"> トラフィックソース </h4>
                  </div>
                  <div class="col-md-4 text-right">
                    <i class="fa" :class="sign('traffic')"></i>
                  </div>
                </a>
              <div class="card-body collapse" id="trafficSourceChart">
                <div ref="traffic_source_chart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-12 col-md-12">
          <div v-bind:class="gridWidthClass">
            <div class="card card-default collapsed-box my-2">
              <a
                href="#"
                class="row px-3 py-2"
                @click="expand('country')"
                :data-toggle="collapse"
                data-target="#countryPercentages"
              >
                <div class ="col-md-8">
                  <h4 class="card-title"> 主な国 </h4>
                </div>
                <div class="col-md-4 text-right">
                  <i class="fa" :class="sign('country')"></i>
                </div>
              </a>
              <div class="card-body collapse" id="countryPercentages">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>国名</th>
                      <th class="text-right">割合</th>
                    </tr>
                    <tr
                      v-for="country in countryPercentages"
                      v-bind:key="country.id"
                      v-bind:item="country"
                    >
                      <td>{{ country.name }}</td>
                      <td>{{ country.percentage }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as c3 from "c3";
import axios from "axios";
import Rails from "rails-ujs";

export default {
  props: [
    "api_endpoint",
    "csv_download_endpoint",
    "ids",
    "list_id",
    "display_extend_metrics",
    "grid_width"
  ],
  data() {
    return {
      countryPercentages: [],
      toggleProcessing: false, // 視聴者属性のUIアニメーション中の操作を回避するためのフラグ
      showFlg: {
        // 視聴者属性の各要素の状態管理
        gender: true,
        age: true,
        country: false,
        device: false,
        os: false,
        location: false,
        traffic: false
      }
    };
  },
  computed: {
    collapse() {
      return this.toggleProcessing ? "" : "collapse";
    },
    sign() {
      // トグルスイッチの表示切り替え
      return key => (this.showFlg[key] ? "fa-minus" : "fa-plus");
    },
    displayExtendMetrics() {
      return (
        this.display_extend_metrics === 1 || this.display_extend_metrics === "1"
      );
    },
    gridWidthClass() {
      return `col-md-${this.grid_width}`;
    }
  },
  methods: {
    expand(key) {
      if (this.toggleProcessing) return;
      this.toggleProcessing = true;
      this.showFlg[key] = !this.showFlg[key];
      setTimeout(() => {
        // チャートがオープン・クローズのアニメーションと整合性をもたせるため
        // トグルスイッチを一瞬無効化する
        this.toggleProcessing = false;
      }, 500);
    },
    downloadCsv() {
      const link = document.createElement("a");
      link.setAttribute("data-method", "post");
      link.setAttribute("href", `${this.csv_download_endpoint}`);
      link.addEventListener("click", Rails.handleMethod);
      link.click();
    },
    requestData() {
      const data = {};
      const self = this;
      if (this.ids) {
        data.ids = this.ids.split(",");
      }
      if (this.list_id) {
        data.list_id = this.list_id;
      }
      (async () => {
        const response = await axios.get(this.api_endpoint, {
          params: data,
          responseType: "json"
        });
        self.countryPercentages = response.data.country_percentages;
        self.createAgeGroupChart(response.data.age_groups);
        self.createGenderChart(response.data.genders);
        self.createDeviceChart(response.data.device_types);
        self.createOsChart(response.data.os_types);
        self.createPlaybackLocationChart(response.data.playback_locations);
        self.createTrafficSourceChart(response.data.traffic_sources);
      })();
    },
    createAgeGroupChart(source) {
      c3.generate({
        bindto: this.$refs.age_group_chart,
        data: {
          order: null,
          columns: source,
          type: "bar"
        },
        axis: {
          y: {
            tick: {
              format: d3.format(".2%")
            }
          }
        },
        bar: {
          width: {
            ratio: 1
          }
        }
      });
    },
    createGenderChart(source) {
      c3.generate({
        bindto: this.$refs.gender_chart,
        legend: {
          position: "right"
        },
        data: {
          columns: source,
          type: "donut"
        }
      });
    },
    createDeviceChart(source) {
      c3.generate({
        bindto: this.$refs.device_chart,
        legend: {
          position: "right"
        },
        data: {
          columns: source,
          type: "donut"
        }
      });
    },
    createOsChart(source) {
      c3.generate({
        bindto: this.$refs.os_chart,
        legend: {
          position: "right"
        },
        data: {
          columns: source,
          type: "donut"
        }
      });
    },
    createPlaybackLocationChart(source) {
      c3.generate({
        bindto: this.$refs.playback_location_chart,
        legend: {
          position: "right"
        },
        data: {
          columns: source,
          type: "donut"
        }
      });
    },
    createTrafficSourceChart(source) {
      c3.generate({
        bindto: this.$refs.traffic_source_chart,
        legend: {
          position: "right"
        },
        data: {
          columns: source,
          type: "donut"
        }
      });
    }
  },
  mounted() {
    this.requestData();
  }
};
</script>
