import uuumChannelsSummary from "../../api/uuumChannels/summary"

const state = {
  summaries: [],
  reportStartDate: null,
  reportEndDate: null,
}

const getters = {
}

const mutations = {
  clearAllData (state) {
    state.summaries = []
    state.reportStartDate = null
    state.reportEndDate = null
  },
  setSummaryData (state, data) {
    state.summaries = data
  },
  setReportDate (state, { startDate, endDate }) {
    state.reportStartDate = startDate
    state.reportEndDate = endDate
  },
}

const actions = {
  async reloadAll ({ commit }, { params }) {
    commit("clearAllData")
    try {
      const summaryResponse = await uuumChannelsSummary(params)
      commit("setSummaryData", summaryResponse.data.summaries)
      commit("setReportDate", { startDate: summaryResponse.data.reportStartDate, endDate: summaryResponse.data.reportEndDate})
    } catch (err) {
      alert(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
