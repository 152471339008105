<template>
  <div>
    <div class="card">
      <div class="card-header-primary card-header-text">
        <div class="card-text">
          <h4 class="card-title">視聴者属性（UUUMのみ）</h4>
        </div>
        <h6 class="pull-right text-warning mt-3 mr-2">※集計期間は今までの累計です</h6>
      </div>
      <div class="card-body">
        <youtube-viewer-report
          grid_width="12"
          display_extend_metrics="0"
          :api_endpoint="demographicsApiUrl"
          :csv_download_endpoint="demographicsCsvDownloadUrl"
          :list_id="list_id"
        />
      </div>
    </div>
    <div class="card">
      <div class="card-header-primary card-header-text">
        <div class="card-text">
          <h4 class="card-title">実データグラフ（UUUMのみ）</h4>
        </div>
      </div>
      <div class="card-body">
        <youtube-channel-chart
          :api_endpoint="dailyChartApiUrl"
          :csv_download_endpoint="dailyChartCsvDownloadUrl"
          :list_id="list_id"
        />
      </div>
    </div>
    <div class="card">
      <div class="card-header-primary card-header-text">
        <div class="card-text">
          <h4 class="card-title">全体サマリ</h4>
        </div>
      </div>
      <div class="card-body">
        <h4>指定月</h4>
        <div class="row">
          <div class="col-md-12">指定した月は、サマリ・一覧・CSVに反映されます</div>
        </div>
        <div class="row mb-5 pl-3">
          <div class="col-md-7">
            <select
              class="selectpicker col-md-2"
              data-style="select-with-transition pr-0"
              v-model="year"
            >
              <option v-for="value in years" v-bind:value="value">{{ value }}</option>
            </select>
            <label class="mt-3">年</label>
            <select
              class="selectpicker col-md-2"
              data-style="select-with-transition pr-0"
              v-model="month"
            >
              <option v-for="value in months" v-bind:value="value">{{ value }}</option>
            </select>
            <label class="mt-3">月</label>
            <div class="col-md-12 mt-3">
              <span
                class="text-sucess ml-2"
                v-if="showReportDate"
              >現在 {{ reportStartDate | date_default }} 〜 {{ reportEndDate | date_default }} のデータを表示しています。</span>
              <span
                class="text-primary ml-2"
                v-else-if="!showReportDate && !loadingSummary"
              >指定した月のデータは、まだ集計されていません</span>
            </div>
          </div>
        </div>
        <div v-show="loading" class="row">
          <div class="col-md-5"/>
          <div class="col-md-2">
            <pulse-loader :loading="loading" :color="loaderColor" :size="loaderSize"/>
          </div>
          <div class="col-md-5"/>
        </div>
        <div v-show="!loading">
          <div>
            <div class="row">
              <div class="col-xl-4 col-lg-6 col-md-12" v-if="summary.viewCount">
                <div class="card card-stats pb-3">
                  <div class="card-header card-header-success card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">play_arrow</i>
                    </div>
                    <div class="card-category">再生回数</div>
                    <h3 class="card-title mb-2">{{ summary.viewCount.monthly | delimited_int }}</h3>
                    <div class="card-footer-custom">
                      <div class="row">
                        <div class="col-md-6 text-left">前月</div>
                        <div class="col-md-6">{{ summary.viewCount.lastMonthly | delimited_int }}</div>
                       </div>
                       <div class="row">
                         <div class="col-md-6 text-left">着地</div>
                         <div class="col-md-6">{{ summary.viewCount.monthlyLanding | delimited_int }}</div>
                       </div>
                       <div class="row">
                         <div class="col-md-6 text-left">前月比</div>
                         <div class="col-md-6">{{ summary.viewCount.m2mRate | delimited_float }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12" v-if="summary.subscribersCount">
                <div class="card card-stats pb-3">
                  <div class="card-header card-header-warning card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">person</i>
                    </div>
                    <div class="card-category">チャンネル登録者数</div>
                    <h3 class="card-title mb-2">{{ summary.subscribersCount.monthly | delimited_int }}</h3>
                    <div class="card-footer-custom">
                      <div class="row">
                        <div class="col-md-6 text-left">前月</div>
                        <div class="col-md-6">{{ summary.subscribersCount.lastMonthly | delimited_int }}</div>
                      </div>
                      <div class="row">
                          <div class="col-md-6 text-left">着地</div>
                          <div class="col-md-6">{{ summary.subscribersCount.monthlyLanding | delimited_int }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 text-left">前月比</div>
                        <div class="col-md-6">{{ summary.subscribersCount.m2mRate | delimited_float }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12" v-if="summary.videoCount">
                <div class="card card-stats pb-3">
                  <div class="card-header card-header-info card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">subscriptions</i>
                    </div>
                    <div class="card-category">動画公開数</div>
                    <h3 class="card-title mb-2">{{ summary.videoCount.monthly | delimited_int }}</h3>
                    <div class="card-footer-custom">
                      <div class="row">
                        <div class="col-md-6 text-left">前月</div>
                        <div class="col-md-6">{{ summary.videoCount.lastMonthly | delimited_int }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 text-left">着地</div>
                        <div class="col-md-6">{{ summary.videoCount.monthlyLanding | delimited_int }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 text-left">前月比</div>
                        <div class="col-md-6">{{ summary.videoCount.m2mRate | delimited_float }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12" v-if="summary.commentCount">
                <div class="card card-stats pb-3">
                  <div class="card-header card-header-rose card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">comment</i>
                    </div>
                    <div class="card-category">コメント数</div>
                    <h3 class="card-title mb-2">{{ summary.commentCount.monthly | delimited_int }}</h3>
                    <div class="card-footer-custom">
                      <div class="row">
                        <div class="col-md-6 text-left">前月</div>
                        <div class="col-md-6">{{ summary.commentCount.lastMonthly | delimited_int }}</div>
                      </div>
                      <div class="row">
                          <div class="col-md-6 text-left">着地</div>
                          <div class="col-md-6">{{ summary.commentCount.monthlyLanding | delimited_int }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 text-left">前月比</div>
                        <div class="col-md-6">{{ summary.commentCount.m2mRate | delimited_float }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12" v-if="summary.estimatedRevenue">
                <div class="card card-stats pb-3">
                  <div class="card-header card-header-success card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">attach_money</i>
                    </div>
                    <div class="card-category">推定収益額</div>
                    <h3 class="card-title mb-2">{{ summary.estimatedRevenue.monthly | delimited_float }}</h3>
                    <div class="card-footer-custom">
                      <div class="row">
                        <div class="col-md-6 text-left">前月</div>
                        <div class="col-md-6">{{ summary.estimatedRevenue.lastMonthly | delimited_float }}</div>
                      </div>
                      <div class="row">
                          <div class="col-md-6 text-left">着地</div>
                          <div class="col-md-6">{{ summary.estimatedRevenue.monthlyLanding | delimited_float }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 text-left">前月比</div>
                        <div class="col-md-6">{{ summary.estimatedRevenue.m2mRate | delimited_float }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12" v-if="summary.rpm">
                <div class="card card-stats pb-3">
                  <div class="card-header card-header-warning card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">show_chart</i>
                    </div>
                    <div class="card-category">RPM</div>
                    <h3 class="card-title mb-2">{{ summary.rpm.monthly | delimited_rpm }}</h3>
                    <div class="card-footer-custom">
                      <div class="row">
                        <div class="col-md-6 text-left">前月</div>
                        <div class="col-md-6">{{ summary.rpm.lastMonthly | delimited_rpm }}</div>
                      </div>
                      <div class="row">
                          <div class="col-md-6 text-left">着地</div>
                          <div class="col-md-6">{{ summary.rpm.monthlyLanding | delimited_rpm }}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 text-left">前月比</div>
                        <div class="col-md-6">{{ summary.rpm.m2mRate | delimited_float }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header card-header-tabs card-header-primary">
              <div class="nav-tabs-navigation">
                <div class="nav-tabs-wrapper">
                  <ul class="nav nav-tabs" data-tabs="tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active cs-link-height"
                        href="#activity"
                        data-toggle="tab"
                        @click="changeChannelTab"
                      > チャンネル単位
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link cs-link-height"
                        href="#timeline "
                        data-toggle="tab"
                        @click="changeCreatorTab"
                      > クリエイター単位
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="tab-content" v-if="selectedChannelTab">
                <list-by-channel
                  :list_id="list_id"
                  :year="year"
                  :month="month"
                  :youtube-channels="youtubeChannels"
                />
              </div>
              <div class="tab-content" v-if="selectedCreatorTab">
                <list-by-creator
                  :list_id="list_id"
                  :year="year"
                  :month="month"
                  :youtube-creators="youtubeCreators"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import YoutubeChannelChart from "../components/YoutubeChart/YoutubeChannelChart.vue";
import ListByChannel from "../components/YoutubeChannelList/ListByChannel.vue";
import ListByCreator from "../components/YoutubeChannelList/ListByCreator.vue";
import YoutubeViewerReport from "../components/YoutubeChannelList/ViewerReport.vue";

export default {
  props: {
    list_id: {
      type: Number,
      default: null
    }
  },
  components: {
    PulseLoader,
    YoutubeViewerReport,
    YoutubeChannelChart,
    ListByChannel,
    ListByCreator
  },
  data() {
    return {
      listTab: "channel",
      years: [],
      months: [],
      year: moment().year(),
      month: moment().month() + 1,
      loaderSize: "12px",
      loaderColor: "#FF0000"
    };
  },
  computed: {
    selectedChannelTab() {
      return this.$data.listTab === "channel";
    },
    selectedCreatorTab() {
      return this.$data.listTab === "creator";
    },
    showReportDate() {
      return this.reportStartDate != null && this.reportEndDate != null;
    },
    reportStartDate() {
      return this.$store.state.channelMylist.reportStartDate;
    },
    reportEndDate() {
      return this.$store.state.channelMylist.reportEndDate;
    },
    summary() {
      return this.$store.state.channelMylist.summary;
    },
    loading() {
      return this.$store.state.channelMylist.loading;
    },
    loadingSummary() {
      return this.$store.state.channelMylist.loadingSummary;
    },
    youtubeChannels() {
      return this.$store.state.channelMylist.youtubeChannels;
    },
    youtubeCreators() {
      return this.$store.state.channelMylist.youtubeCreators;
    },
    demographicsApiUrl() {
      return "/api/v1/graph/youtube_channel_viewer_report.json";
    },
    demographicsCsvDownloadUrl() {
      return `/youtube_channels/raw_demographics_csv_download?youtube_channel_list_id=${
        this.list_id
      }`;
    },
    dailyChartApiUrl() {
      return "/api/v1/graph/youtube_channel_analytics.json";
    },
    dailyChartCsvDownloadUrl() {
      return "/youtube_channels/raw_timebased_csv_download";
    }
  },
  methods: {
    reload() {
      this.$store.dispatch("channelMylist/reloadAll", {
        params: this.getRequestParams()
      });
    },
    getRequestParams() {
      const data = {
        year: this.$data.year,
        month: this.$data.month,
        id: this.list_id
      };
      return data;
    },
    changeChannelTab() {
      this.$data.listTab = "channel";
    },
    changeCreatorTab() {
      this.$data.listTab = "creator";
    }
  },
  watch: {
    year() {
      this.reload();
    },
    month() {
      this.reload();
    }
  },
  mounted() {
    this.reload();
  },
  created() {
    const minYear = Math.max(this.$data.year - 10, 2015);
    for (let i = minYear; i <= this.$data.year; i += 1) {
      this.$data.years.push(i);
    }
    for (let i = 1; i <= 12; i += 1) {
      this.$data.months.push(i);
    }
  }
};
</script>
