import Vue from "vue"
import numeral from "numeral"
import { DateTime } from "luxon"

Vue.filter("date_default", value => DateTime.fromISO(value).toFormat("yyyy/LL/dd"))
Vue.filter("delimited_int", value => numeral(value).format("0,0"))
Vue.filter("delimited_float", value => numeral(value).format("0,0.0000"))
Vue.filter("delimited_rpm", value => numeral(value).format("0,0.000000"))

export const agFilterNumber = () => {
  return (params) => {
    if (params.value == null) {
      return ""
    }
    return numeral(params.value).format("0,0")
  }
}

export const agFilterPercentage = ({ digit = 2 }) => {
  return (params) => {
    if (params.value == null) {
      return ""
    }
    const zeroDigit = "0".repeat(digit)
    const format = numeral(params.value).format(`0,0.${zeroDigit}`)
    return `${format}%`
  }
}

export const agFilterDate = () => {
  return (params) => {
    if (params.value == null) {
      return ""
    }
    return DateTime.fromISO(params.value).toFormat("yyyy/LL/dd")
  }
}

export const agFilterImage = () => {
  return (params) => {
    if (params.value == null) {
      return ""
    }
    return `<img src="${params.value}" style="width: 32px; height: 32px;">`
  }
}

export const agFilterDollar = ({ digit = 2 }) => {
  return (params) => {
    if (params.value == null) {
      return ""
    }
    const zeroDigit = "0".repeat(digit)
    const format = numeral(params.value).format(`0,0.${zeroDigit}`)
    return `$${format}`
  }
}
