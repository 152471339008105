<template>
  <div v-if="isShow">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper" @click.self="hide">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button class="close" @click="hide">
                  <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title"><slot name="header"></slot></h4>
              </div>
              <div class="modal-body modal-scrollable">
                <slot></slot>
              </div>
              <div class="modal-footer">
                <slot name="footer"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'Modal',
    computed: {
      ...mapState('modal', ['isShow'])
    },
    methods: {
      ...mapMutations('modal', ['hide'])
    }
  }
</script>
