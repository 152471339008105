<template>
  <div>
    <button class="dropdown-toggle" @click="popupMenuOpen">
      <span>
        <img
          :src="params.value"
          style="width: 32px; height: 32px;">
      </span>
      <span class="caret"/>
      <span class="sr-only">Toggle Dropdown</span>
    </button>
    <ul style="display: none;">
      <li>
        <a
          target="_blank"
          :href="detailPageUrl"
          ref="detailPageLink">詳細</a>
      </li>
      <li>
        <a
          target="_blank"
          :href="youtubeUrl"
          ref="youtubeLink">YouTube</a>
      </li>
      <li>
        <a
          target="_blank"
          :href="socialbladeUrl"
          ref="socialBladeLink">SocialBlade</a>
      </li>
      <li>
        <a
          class="js-add-to-channel-list-dialog"
          ref="mylistAddLink"
          :data-youtube-channel-id="params.data.id"
          :data-youtube-channel-title="params.data.title">マイリスト</a>
      </li>
      <li>
        <a
          target="_blank"
          :href="largeThumbnailUrl"
          ref="largeThumbnailLink">大きいサムネイル画像</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      params: null,
    };
  },
  computed: {
    youtubeUrl() {
      return `https://www.youtube.com/channel/${this.params.data.id}`;
    },
    socialbladeUrl() {
      return `https://socialblade.com/youtube/channel/${this.params.data.id}`;
    },
    detailPageUrl() {
      return `/youtube_channels/${this.params.data.id}`;
    },
    largeThumbnailUrl() {
      return this.params.data.thumbnailLargeImageUrl;
    },
  },
  methods: {
    popupMenuOpen(e) {
      this.pressMenu.open(e);
    },
  },
  mounted() {
    this.pressMenu = new this.$popup.PressMenu({
      propsData: {
        items: [
          {
            name: "詳細",
            click: () => {
              this.$refs.detailPageLink.click();
              this.pressMenu.close();
            },
          },
          {
            name: "YouTube",
            click: () => {
              this.$refs.youtubeLink.click();
              this.pressMenu.close();
            },
          },
          {
            name: "SocialBlade",
            click: () => {
              this.$refs.socialBladeLink.click();
              this.pressMenu.close();
            },
          },
          {
            name: "マイリスト",
            click: () => {
              window.OpenChannelMylistDialog(this.$refs.mylistAddLink); // todo jQuery外す
              this.pressMenu.close();
            },
          },
          {
            name: "サムネイル大",
            click: () => {
              this.$refs.largeThumbnailLink.click();
              this.pressMenu.close();
            },
          },
        ],
      },
    });
  },
});
</script>
