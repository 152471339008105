<template>
<div>
  <div class="form-check">
    <label class="form-check-label">
      <input class="form-check-input" type="checkbox" :value="channelId" @change="modifyScoutTargets" :checked="existScoutTargets">
        <span class="form-check-sign">
          <span class="check"></span>
        </span>
    </label>
  </div>
</div>
</template>

<script>
  import { mapMutations, mapGetters } from 'vuex'
  export default {
    props: ['channelId'],
    computed: {
      existScoutTargets() {
        return this.hasChannel(this.channelId)
      },
      ...mapGetters('youtubeChannels', {
        hasChannel: 'hasChannel'
      }),
    },
    methods: {
      modifyScoutTargets(event) {
        if (event.target.checked) {
          this.$store.commit('youtubeChannels/addChannels', event.target.value)
        } else {
          this.$store.commit('youtubeChannels/removeChannels', event.target.value)
        }
      },
      ...mapMutations('modal', ['hide'])
    }
  }
</script>
