<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-md-12">
        <h2>{{ name }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4" v-if="summary.viewCount">
        <div class="card card-stats">
          <div class="card-header card-header-success card-header-icon">
            <div class="card-icon">
              <i class="material-icons">play_arrow</i>
            </div>
            <div class="card-category">再生回数</div>
            <h3 class="card-title">{{ summary.viewCount.monthly | delimited_int }}</h3>
            <div class="card-footer-custom">
              <div class="row">
                <div class="col-md-6 text-left">前月</div>
                <div class="col-md-6">{{ summary.viewCount.lastMonthly | delimited_int }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">着地</div>
                <div class="col-md-6">{{ summary.viewCount.monthlyLanding | delimited_int }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">前月比</div>
                <div class="col-md-6">{{ summary.viewCount.m2mRate | delimited_float }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4" v-if="summary.subscribersCount">
        <div class="card card-stats">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon">
              <i class="material-icons">person</i>
            </div>
            <div class="card-category">チャンネル登録者数</div>
            <h3 class="card-title">{{ summary.subscribersCount.monthly | delimited_int }}</h3>
            <div class="card-footer-custom">
              <div class="row">
                <div class="col-md-6 text-left">前月</div>
                <div class="col-md-6">{{ summary.subscribersCount.lastMonthly | delimited_int }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">着地</div>
                <div class="col-md-6">{{ summary.subscribersCount.monthlyLanding | delimited_int }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">前月比</div>
                <div class="col-md-6">{{ summary.subscribersCount.m2mRate | delimited_float }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4" v-if="summary.commentCount">
        <div class="card card-stats">
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">comment</i>
            </div>
            <div class="card-category">コメント数</div>
            <h3 class="card-title">{{ summary.commentCount.monthly | delimited_int }}</h3>
            <div class="card-footer-custom">
              <div class="row">
                <div class="col-md-6 text-left">前月</div>
                <div class="col-md-6">{{ summary.commentCount.lastMonthly | delimited_int }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">着地</div>
                <div class="col-md-6">{{ summary.commentCount.monthlyLanding | delimited_int }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">前月比</div>
                <div class="col-md-6">{{ summary.commentCount.m2mRate | delimited_float }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4" v-if="summary.estimatedRevenue">
        <div class="card card-stats">
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <i class="material-icons">attach_money</i>
            </div>
            <div class="card-category">推定収益額</div>
            <h3 class="card-title">{{ summary.estimatedRevenue.monthly | delimited_float }}</h3>
            <div class="card-footer-custom">
              <div class="row">
                <div class="col-md-6 text-left">前月</div>
                <div class="col-md-6">{{ summary.estimatedRevenue.lastMonthly | delimited_float }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">着地</div>
                <div class="col-md-6">{{ summary.estimatedRevenue.monthlyLanding | delimited_float }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">前月比</div>
                <div class="col-md-6">{{ summary.estimatedRevenue.m2mRate | delimited_float }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4" v-if="summary.rpm">
        <div class="card card-stats">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">insert_emoticon</i>
            </div>
            <div class="card-category">RPM</div>
            <h3 class="card-title">{{ summary.rpm.monthly | delimited_rpm }}</h3>
            <div class="card-footer-custom">
              <div class="row">
                <div class="col-md-6 text-left">前月</div>
                <div class="col-md-6">{{ summary.rpm.lastMonthly | delimited_rpm }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">着地</div>
                <div class="col-md-6">{{ summary.rpm.monthlyLanding | delimited_rpm }}</div>
              </div>
              <div class="row">
                <div class="col-md-6 text-left">前月比</div>
                <div class="col-md-6">{{ summary.rpm.m2mRate | delimited_float }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    summary: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: "default",
    },
  }
}
</script>
