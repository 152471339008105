<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="inline-search-criteria col-md-6">
          <h4>指定月</h4>
          <div>
            <select
              class="selectpicker col-md-2"
              data-style="select-with-transition pr-0"
              v-model="year"
            >
              <option v-for="value in years" :value="value" :key="value">{{ value }}</option>
            </select>
            <span>年</span>
            <select
              class="selectpicker col-md-2"
              data-style="select-with-transition pr-0"
              v-model="month"
            >
              <option v-for="value in months" :value="value" :key="value">{{ value }}</option>
            </select>
            <span>月</span>
            <span
              class="ml-4"
              v-if="showReportDate"
            >現在 {{ reportStartDate | date_default }} 〜 {{ reportEndDate | date_default }} のデータを表示しています。</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" v-for="(summary, key) in summaries" :key="key">
          <channel-summary :summary="summary" :name="summary.name"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import YoutubeChannelChart from "../components/YoutubeChart/YoutubeChannelChart.vue";
import ChannelSummary from "../components/UuumChannelsIndex/Summary.vue";

export default {
  components: {
    YoutubeChannelChart,
    ChannelSummary
  },
  data() {
    return {
      years: [],
      months: [],
      year: moment().year(),
      month: moment().month() + 1
    };
  },
  computed: {
    summaries() {
      return this.$store.state.uuumChannels.summaries;
    },
    showReportDate() {
      return this.reportStartDate != null && this.reportEndDate != null;
    },
    reportStartDate() {
      return this.$store.state.uuumChannels.reportStartDate;
    },
    reportEndDate() {
      return this.$store.state.uuumChannels.reportEndDate;
    }
  },
  methods: {
    reload() {
      this.$store.dispatch("uuumChannels/reloadAll", {
        params: this.getRequestParams()
      });
    },
    getRequestParams() {
      const data = {
        year: this.$data.year,
        month: this.$data.month
      };
      return data;
    }
  },
  watch: {
    year() {
      this.reload();
    },
    month() {
      this.reload();
    }
  },
  mounted() {
    this.reload();
  },
  created() {
    const minYear = Math.max(this.$data.year - 10, 2015);
    for (let i = minYear; i <= this.$data.year; i += 1) {
      this.$data.years.push(i);
    }
    for (let i = 1; i <= 12; i += 1) {
      this.$data.months.push(i);
    }
  }
};
</script>
