<template>
  <div>
    <button @click="popupMenuOpen">
      <span>
        <img
          :src="params.value"
          style="width: 32px; height: 32px;">
      </span>
      <span class="caret"/>
      <span class="sr-only">Toggle Dropdown</span>
    </button>
    <ul style="display: none;">
      <li>
        <a
          target="_blank"
          :href="detailPageUrl"
          ref="detailPageLink">詳細</a>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      params: null,
    };
  },
  computed: {
    detailPageUrl() {
      return `/youtube_creators/${this.params.data.id}`;
    },
  },
  methods: {
    popupMenuOpen(e) {
      this.pressMenu.open(e);
    },
  },
  mounted() {
    this.pressMenu = new this.$popup.PressMenu({
      propsData: {
        items: [
          {
            name: "詳細",
            click: () => {
              this.$refs.detailPageLink.click();
              this.pressMenu.close();
            },
          },
        ],
      },
    });
  },
});
</script>
