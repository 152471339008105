import Vue from "vue"
import Vuex from "vuex"

import createPersistedState from 'vuex-persistedstate'
import * as modules from "./modules"

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  plugins: [
    createPersistedState({
      key: 'youtube_channels_session',
      paths: ['youtubeChannels'],
      storage: window.sessionStorage
    }),
  ],
})
