<template>
  <div>
    <div class="row">
      <div class="col-md-5">
        <div class="form-group row">
          <label>公開日から</label>
          <input class="form-control col-md-4 mr-2 mt-3" type="number" v-model="date_begin" min="0"><span class="mt-4">日目〜 </span>
          <input class="form-control col-md-4 ml-2 mt-3" type="number" v-model="date_end" min="1"><span class="mt-4">日目 </span>
        </div>
      </div>
      <div class="col-md-3 col-sm-6">
        <label class="mb-0">項目</label>
        <select class="selectpicker col-md-12 pl-0" data-style="select-with-transition pr-0" v-model="metrics">
          <option value="views">再生数</option>
          <option value="likes">高評価数</option>
          <option value="dislikes">低評価数</option>
          <option value="comments">コメント数</option>
          <option value="estimated_minutes_watched">再生時間</option>
          <option value="average_view_duration">平均視聴時間</option>
          <option value="estimated_revenue">収益</option>
          <option value="rpm">RPM</option>
        </select>
      </div>
      <div class="col-md-3 col-sm-6 mt-4 ml-2">
        <div class="row">
          <div class="form-check col-md-5 col-sm-6">
            <label class="form-check-label">
              <input class="form-check-input" checked="checked" name="video_analytics_graph_type" type="radio" v-on:click="changeDataTypeToLine" value="1">折れ線
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check col-md-5 col-sm-6">
            <label class="form-check-label">
              <input class="form-check-input" name="video_analytics_graph_type" type="radio" v-on:click="changeDataTypeToArea" value="2">積み重ね
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-default" v-on:click="downloadVideoCsv">CSVダウンロード</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date_begin: 0,
      date_end: 30,
      data_type: "line",
      metrics: "views",
    };
  },
  watch: {
    metrics() {
      if (this.$data.metrics === "") {
        return;
      }
      this.$emit("reloadChart");
    },
    date_begin() {
      if (this.$data.date_begin === "") {
        return;
      }
      this.$emit("reloadChart");
    },
    date_end() {
      if (this.$data.date_end === "") {
        return;
      }
      this.$emit("reloadChart");
    },
  },
  methods: {
    downloadVideoCsv() {
      this.$emit("downloadVideoCsv");
    },
    changeDataTypeToLine() {
      this.$emit("changeDataTypeToLine");
    },
    changeDataTypeToArea() {
      this.$emit("changeDataTypeToArea");
    },
    getRequestParams() {
      const data = {
        time_type: "published_at",
        date_begin: this.$data.date_begin,
        date_end: this.$data.date_end,
        metrics: this.$data.metrics,
      };
      return data;
    },
  },
};
</script>
