<template>
  <div class="card mt-5">
    <div class="card-header card-header-tabs card-header-primary">
      <div class="nav-tabs-navigation">
        <div class="nav-tabs-wrapper">
          <ul class="nav nav-tabs" data-tabs="tabs">
            <li class ="nav-item cs-link-height" v-bind:class="pageTabClass(0)" v-on:click="changeTab(0)">
              <a href="#activity" class="nav-link active" data-toggle="tab" aria-expanded="true">日付基準</a>
            </li>
            <li class ="nav-item cs-link-height" v-bind:class="pageTabClass(1)" v-on:click="changeTab(1)">
              <a href="#timeline" class="nav-link" data-toggle="tab" aria-expanded="false">公開日基準</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-show="canDisplayPage(0)">
        <YoutubeVideoMenuDate
          ref="YoutubeVideoMenuDate"
          v-on:reloadChart="reloadChart"
          v-on:downloadVideoCsv="downloadVideoCsv"
          v-on:changeDataTypeToLine="changeDataTypeToLine"
          v-on:changeDataTypeToArea="changeDataTypeToArea">
        </YoutubeVideoMenuDate>
      </div>
      <div v-show="canDisplayPage(1)">
        <YoutubeVideoMenuPublishedAt
          ref="YoutubeVideoMenuPublishedAt"
          v-on:reloadChart="reloadChart"
          v-on:downloadVideoCsv="downloadVideoCsv"
          v-on:changeDataTypeToLine="changeDataTypeToLine"
          v-on:changeDataTypeToArea="changeDataTypeToArea">
        </YoutubeVideoMenuPublishedAt>
      </div>
     <div ref="chart"></div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import axios from "axios";
import YoutubeChartBase from "./YoutubeChartBase.vue";
import YoutubeVideoMenuDate from "./YoutubeVideoMenuDate.vue";
import YoutubeVideoMenuPublishedAt from "./YoutubeVideoMenuPublishedAt.vue";

const PAGE_DATE = 0;
const PAGE_PUBLISHED_AT = 1;

export default {
  mixins: [YoutubeChartBase],
  props: ["api_endpoint", "csv_download_endpoint", "ids", "list_id"],
  components: {
    YoutubeVideoMenuDate,
    YoutubeVideoMenuPublishedAt,
  },
  data() {
    return {
      date_begin: moment().subtract(3 + 7, "days").format("YYYY-MM-DD"),
      date_end: moment().subtract(3, "days").format("YYYY-MM-DD"),
      groups: [],
      data_type: "line",
      metrics: "views",
      page: PAGE_DATE,
    };
  },
  methods: {
    canDisplayPage(page) {
      return (this.$data.page === page);
    },
    pageTabClass(page) {
      if (this.canDisplayPage(page)) {
        return "active";
      }
      return "";
    },
    async changeTab(page) {
      this.$data.page = page;
      await this.$nextTick();
      this.reloadChart();
    },
    downloadVideoCsv() {
      this.downloadRawCsv(this.getRequestParams());
    },
    getCurrentComponent() {
      if (this.$data.page === PAGE_DATE) {
        return this.$refs.YoutubeVideoMenuDate;
      } else if (this.$data.page === PAGE_PUBLISHED_AT) {
        return this.$refs.YoutubeVideoMenuPublishedAt;
      }
      return null;
    },
    getRequestParams() {
      const data = this.getCurrentComponent().getRequestParams();
      if (this.ids != null) {
        data.ids = this.ids.split(",");
      }
      if (this.list_id != null) {
        data.list_id = this.list_id;
      }
      return data;
    },
    async reloadChart() {
      const data = this.getRequestParams();
      const response = await axios.get(this.api_endpoint, {
        params: data,
        responseType: "json",
      });
      const { videos } = response.data;
      if (data.time_type === "date") {
        await this.createChart(this.$refs.chart, this.chartXaxisTimeseriesSetting());
      } else {
        await this.createChart(this.$refs.chart, this.chartXaxisIndexedSetting());
      }
      this.renderChart(videos);
    },
  },
  async mounted() {
    await this.createChart(this.$refs.chart, this.chartXaxisTimeseriesSetting());
    await this.$nextTick();
    this.reloadChart();
  },
};
</script>
